.badge {
  &.fontevo-badge-white {
    background-color: $color__white;
    color: $color__dark-grey;

    &:hover {
      background-color: $color__green;
      color: $color__white;
    }
  }
}

.txt__size--l {
  font-size: 3rem;
}
