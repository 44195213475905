

/* PAGES */
.page {

  &__border {

    @include media-breakpoint-up(lg) {
      border: $content-border #fff solid;
    }

    min-height: 100vh;
  }

  &__padding {

    &--top {
      padding-top: 7rem;

      @include media-breakpoint-up(lg) {
        padding-top: 9rem;
      }
    }

    &--mintop {
      padding-top: $navigation_height_responsive;

      @include media-breakpoint-up(lg) {
        padding-top: 9rem;
      }
    }
  }

  &__context {

    &--primary {

      @include gradient__right--wave(theme-color('primary'), theme-color('primary-25'), theme-color('primary-dark-90'), '../assets/images/backgrounds/bg-wave-blue-full.svg', top, fixed);
      @include page--color($white);

      .section__context {

        &--white {

          //TODO collect general styles

          @include block--feature(theme-color('primary'), theme-color('primary'), theme-color('primary'));
        }
      }
    }

    &--red {

      @include gradient__right--wave(theme-color('ci-red'), theme-color('ci-red-25'), theme-color('ci-red-dark-90'), '../assets/images/backgrounds/bg-wave-orange-full.svg', top, fixed);
      @include page--color($white);
      @include block--card(theme-color('ci-red'), theme-color('ci-grey'));
      @include block--feature($white, $white, $white);

      .section__context {

        &--red {

          @include section--items($white);

          @include block--feature($white, $white, $white);
          @include block--card(theme-color('ci-red'), theme-color('ci-grey'));
        }

        &--white {

          @include section--items(theme-color('ci-red'));

          @include block--feature(theme-color('ci-red'), theme-color('ci-grey'), theme-color('ci-red'));
          @include block--card($white, $white);
        }
      }
    }

    &--orange {

      @include gradient__right--wave(theme-color('secondary'), theme-color('secondary-25'), theme-color('secondary-dark-90'), '../assets/images/backgrounds/bg-wave-orange-full.svg', top, fixed);
      @include page--color($white);
    }
  }
}

.section {

  &__white {

    &--primary {

      background-color: $white;

      .card__body {
        @include gradient__right(theme-color('primary'), theme-color('primary-25'), theme-color('primary-dark-90'));

        h2, h3, h4, h5, h6, p, label {
          color: $white;
        }
      }
    }

    &--secondary {

      .card__body {
        @include gradient__right(theme-color('secondary'), theme-color('secondary-25'), theme-color('secondary-dark-90'));

        h2, h3, h4, h5, h6, p, label {
          color: $white;
        }
      }
    }

    &--violet {

      background-color: $white;

      h1, h2, h3, h4, h5, h6, p, label {
        color: theme-color('ci-violet');
      }

      .card__body {
        @include gradient__right(theme-color('ci-violet'), theme-color('ci-violet-25'), theme-color('ci-violet-dark-90'));

        h2, h3, h4, h5, h6, p, label {
          color: $white;
        }
      }
    }

    &--red {
      background-color: $white;

      h2 {
        color: theme-color('ci-red');
      }

      .card__body {
        @include gradient__right(theme-color('ci-red'), theme-color('ci-red-25'), theme-color('ci-red-dark-90'));

        h2, h3, h4, h5, h6, p, label {
          color: $white;
        }
      }
    }
  }

  &__orange--white {
    background-color: theme-color('ci-orange');

    h2 {
      color: $white;
    }
  }
}


// Context

.context {

  &__products {

    &--container {
      @extend .container;

      padding: 0;

      @include media-breakpoint-up(lg) {
        padding: 0 15px;
      }
    }
  }

  &__index {

    & .header__born {
      display: block;
    }

    & .navigation__border {
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }
  }

  &__productdetails {

    & .header__switch {

      display: block;
    }

    &--reseller {

      & .header__switch {

        display: block;
      }
    }
  }

  &__solutions {

    background-color: theme-color('secondary');
  }
}

.icon {

  &__quote {

    display: block;
    background-image: url($img-fon-path + 'images/container/quote.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__company {
    font-size: 6rem;
  }
}


// GENERAL

a {
  color: $white;

  &:hover {
    text-decoration: underline;
    color: $white;
  }
}

.products {

  &__btn {
    width: 100%;
  }
}


/* Title Section */

.titlesection {

  &__paragraph {
    color: white;

    @include rfs(18.4px);

    margin: 0;
  }

  &__listicon {
    color: white;
    @include rfs(18.4px);
  }
}

.input {

  &__checkbox {

    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    & input {

      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ &--checkmark {
      background-color: theme-color('ci-grey');
    }

    /* When the checkbox is checked, add a blue background */
    & input:checked ~ &--checkmark {
      background-color: theme-color('primary');
    }

    /* Show the checkmark when checked */
    & input:checked ~ &--checkmark:after {
      display: block;
    }

    & &--checkmark:after {
      left: 7px;
      top: 4px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    /* Create a custom checkbox */
    &--checkmark {

      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: theme-color('ci-grey-light');

      &:after {

        content: "";
        position: absolute;
        display: none;
      }
    }
  }
}

.link {

  &__underline {
    text-decoration: underline;
  }
}

.txt {

  &__center {
    text-align: center;
  }

  &__twocolumns {

    -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2;
    -moz-column-fill: balance;
    column-fill: balance;
  }

  &__font {

    &--primary {
      font-family: $font-family-sans-serif;
    }

    &--secondary {
      font-family: $font-family-secondary;
    }
  }

  &__size {

    &--sm {
      // Font-Size 18px; 2.25rem
      @include rfs(1.125rem, true);
    }

    &--md {
      // Font-Size 26px; 2.25rem
      @include rfs(1.625rem, true);
    }

    &--lg {
      // Font-Size 36px; 2.25rem
      @include rfs(2.25rem, true);
    }

    &--xl {
      // Font-Size 88px; 5.5rem
      @include rfs(5.5rem, true);
    }

    &--xxl {
      // Font-Size 140px; 8.75rem
      @include rfs(8.75rem, true);
    }
  }

  &__weight {

    &--normal {
      font-weight: normal;
    }

    &--bold {
      font-weight: bold;
    }

    &--light {
      font-weight: lighter;
    }
  }

  &__color {

    &--grey {
      color: #555555;
    }

    &--white {
      color: $white;
    }

    &--red {
      color: theme-color('ci-red');
    }

    &--primary {
      color: theme-color('primary');
    }

    &--violet {
      color: theme-color('ci-violet');
    }
  }
}

/* Grey */

a.txt__color--grey:hover {
  color: #555555;
  text-decoration: underline;
}

.dspl {
  &__none {
    display: none !important;
  }
}

:root {
  background-color: theme-color('primary');
}

.orbittour-container {
  min-height: 450px;

  @include media-breakpoint-up(sm) {
    min-height: 500px;
  }

  @include media-breakpoint-up(md) {
    min-height: 550px;
  }

  @include media-breakpoint-up(lg) {
    min-height: 600px;
  }
}

/* Img */

.img {
  &__card {
    &--sm {
      width: 100%;
      height: auto;
    }
  }

  &__cover {
    &--lg {
      @include media-breakpoint-up(lg) {
        height: 100%;
        width: 100%;

        object-fit: cover;
      }
    }
  }
}



//TODO refactor for UI package
.block__context--primary {
  h2, h3, h4, h5, h6, strong {
    color: theme-color('primary');
  }

  a {
    color: theme-color('primary');
    text-decoration: underline;
  }

  color: theme-color('ci-grey');
}

// PopUP Styling
.modal-content > .modal-body {
  color: theme-color('ci-grey');
}

.text__truncate--three {
  display: -webkit-box;
  -webkit-box-orient: vertical;

  /* to specify the number of lines you want the text to run through... */
  -webkit-line-clamp: 3;
  /* hide the overflowing text, i.e, texts that did not fit in to the box */
  overflow: hidden;
}
