.card-black {
  color: $color__black;
}

.card {
  border: none;
}

.card__header {
  margin-left: 1rem;
}
